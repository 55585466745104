import React from "react";
import style from "./Style.module.scss";

function Logo() {
  return (
    <div className={style.logo}>
      <div className={style.banner}>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
        <div className={style.panel}></div>
      </div>
    </div>
  );
}

export default Logo;
