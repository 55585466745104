// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// firebase login
const firebaseSignIn = async () => {
  try {
    await signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_EMAIL,
      process.env.REACT_APP_PASSWORD
    );
  } catch (err) {
    console.log(err);
  }
};

const firebaseSignOut = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    console.log(err);
  }
};

// export
export default db;
export { firebaseSignIn, firebaseSignOut };
